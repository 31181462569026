import { RouteConfig } from 'vue-router';
import { RoutingRouteEnum } from './routes.enum';
import { useCreateApplicationType } from './guards/create-application-type/useCreateApplicationType';
import { useAccountCompletionGuard } from './guards/account-completion-guard/index';
import { PD_ADMIN_ROUTES } from './route-groups/pd-admin';
import { FINANCE_ADMIN_ROUTES } from '@/router/route-groups/finance-admin';

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: RoutingRouteEnum.Home,
    component: () => import(/* webpackChunkName: "partnerHome" */ '../domains/home/views/welcome/Welcome.vue'),
    meta: { requireAuth: true, requireAccountStepsCompletion: false },
    beforeEnter: (to, from, next) => useAccountCompletionGuard(to, from, next),
  },
  {
    path: '/login',
    name: RoutingRouteEnum.LogIn,
    component: () => import(/* webpackChunkName: "authPartner" */ '../domains/authentication/views/login/Login.vue'),
  },
  {
    path: '/logout',
    name: RoutingRouteEnum.Logout,
  },
  {
    path: '/register',
    name: RoutingRouteEnum.Register,
    component: () =>
      import(/* webpackChunkName: "authPartner" */ '../domains/authentication/views/register/Register.vue'),
  },
  {
    path: '/forgot-password',
    name: RoutingRouteEnum.ForgotPassword,
    component: () =>
      import(
        /* webpackChunkName: "authPartner" */ '../domains/authentication/views/forgot-password/ForgotPassword.vue'
      ),
  },
  {
    path: '/update-password',
    name: RoutingRouteEnum.ChangePassword,
    component: () =>
      import(
        /* webpackChunkName: "authPartner" */ '../domains/authentication/views/update-password/UpdatePassword.vue'
      ),
  },
  {
    path: '/verify-email',
    name: RoutingRouteEnum.VerifyEmail,
    component: () =>
      import(/* webpackChunkName: "authPartner" */ '../domains/authentication/views/confirm-email/VerifyEmail.vue'),
  },
  {
    path: '/applications',
    name: RoutingRouteEnum.Applications,
    component: () =>
      import(/* webpackChunkName: "partnerApps" */ '../domains/applications/views/applications/Applications.vue'),
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
  },
  {
    path: '/create-application',
    name: RoutingRouteEnum.CreateApplication,
    component: () =>
      import(
        /* webpackChunkName: "partnerApps" */ '../domains/applications/views/create-application/CreateApplication.vue'
      ),
    meta: { requireAuth: true, requireAccountStepsCompletion: true, createApplicationStep: true },
  },
  {
    path: '/create-application-steps',
    name: RoutingRouteEnum.CreateApplication_StepsContainer,
    component: () =>
      import(
        /* webpackChunkName: "partnerCreateApp" */ '../domains/applications/views/create-application/create-steps/CreateStepsContainer.vue'
      ),
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    children: [
      {
        path: 'general-settings',
        name: RoutingRouteEnum.CreateApplication_GeneralSettings_Normal,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateApp" */ '../domains/applications/views/create-application/create-steps/general-settings/GeneralSettings.vue'
          ),
        beforeEnter: (to, from, next) => useCreateApplicationType(to, from, next),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createApplicationStep: true },
      },
      {
        path: 'application-details',
        name: RoutingRouteEnum.CreateApplication_AppDetails,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateApp" */ '../domains/applications/views/create-application/create-steps/app-details/ApplicationDetails.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createApplicationStep: true },
      },
      {
        path: 'webhook-management',
        name: RoutingRouteEnum.CreateApplication_WebhookManagement,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateApp" */ '../domains/applications/views/create-application/create-steps/webhook-management/WebhookManagement.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createApplicationStep: true },
      },
      {
        path: 'plans-management',
        name: RoutingRouteEnum.CreateApplication_PlansManagement,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateApp" */ '../domains/applications/views/create-application/create-steps/plans-management/PlansManagement.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createApplicationStep: true },
      },
      {
        path: 'publish',
        name: RoutingRouteEnum.CreateApplication_Publish,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateApp" */ '../domains/applications/views/create-application/create-steps/publish/Publish.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createApplicationStep: true },
      },
    ],
  },
  {
    path: '/manage-private-plans/:id',
    name: RoutingRouteEnum.ManagePrivatePlans,
    component: () =>
      import(
        /* webpackChunkName: "partnerCreateApp" */ '../domains/applications/views/create-application/manage-private-plans/ManagePrivatePlans.vue'
      ),
    meta: { requireAuth: true, requireAccountStepsCompletion: true, isCreateAppStepExtension: true },
  },
  {
    path: '/manage-merchant-reviews/:id',
    name: RoutingRouteEnum.ManageMerchantAppsReviews,
    component: () =>
      import(
        /* webpackChunkName: "partnerCreateApp" */ '../domains/applications/views/create-application/manage-merchant-reviews/ManageMerchantAppsReviews.vue'
      ),
    meta: { requireAuth: true, requireAccountStepsCompletion: true, isCreateAppStepExtension: true },
  },
  {
    path: '/create-shipping-application',
    name: RoutingRouteEnum.CreateShippingApplication,
    component: () =>
      import(/* webpackChunkName: "partnerApps" */ '../domains/shipping/views/CreateShippingApplication.vue'),
    meta: { requireAuth: true, requireAccountStepsCompletion: true, createShippingApplicationStep: true },
  },
  {
    path: '/create-shipping-application-steps',
    name: RoutingRouteEnum.CreateShippingApplication_StepsContainer,
    component: () =>
      import(
        /* webpackChunkName: "partnerCreateShippingApp" */ '../domains/shipping/views/create-steps/CreateStepsContainer.vue'
      ),
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    children: [
      {
        path: 'company-info',
        name: RoutingRouteEnum.CreateShippingApplication_CompanyInfo,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateShippingApp" */ '../domains/shipping/views/create-steps/company-info/CompanyInfo.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createShippingApplicationStep: true },
      },
      {
        path: 'general-settings',
        name: RoutingRouteEnum.CreateShippingApplication_GeneralSettings,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateShippingApp" */ '../domains/shipping/views/create-steps/general-settings/GeneralSettings.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createShippingApplicationStep: true },
      },
      {
        path: 'about-company',
        name: RoutingRouteEnum.CreateShippingApplication_AboutCompany,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateShippingApp" */ '../domains/shipping/views/create-steps/about-company/AboutCompany.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createShippingApplicationStep: true },
      },
      {
        path: 'shipping-option-details',
        name: RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateShippingApp" */ '../domains/shipping/views/create-steps/shipping-option-details/ShippingOptionDetails.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createShippingApplicationStep: true },
      },
      {
        path: 'webhook-management',
        name: RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateShippingApp" */ '../domains/shipping/views/create-steps/webhook-management/WebhookManagement.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createShippingApplicationStep: true },
      },
      {
        path: 'publish',
        name: RoutingRouteEnum.CreateShippingApplication_Publish,
        component: () =>
          import(
            /* webpackChunkName: "partnerCreateShippingApp" */ '../domains/shipping/views/create-steps/publish/Publish.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: true, createShippingApplicationStep: true },
      },
    ],
  },
  {
    path: '/dashboard',
    name: RoutingRouteEnum.Dashboard,
    component: () =>
      import(/* webpackChunkName: "partnerHome" */ '../domains/home/views/dashboard/DashboardContainer.vue'),
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    beforeEnter: (to, from, next) => useAccountCompletionGuard(to, from, next),
  },
  {
    path: '/shipping-dashboard',
    name: RoutingRouteEnum.ShippingDashboard,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(/* webpackChunkName: "partnerHome" */ '../domains/home/views/shipping-dashboard/ShippingDashboard.vue'),
  },
  {
    path: '/themes-dashboard',
    name: RoutingRouteEnum.ThemesDashboard,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(/* webpackChunkName: "partnerHome" */ '../domains/themes/views/dashboard/ThemesDashboard.vue'),
  },
  {
    path: '/themes-management',
    name: RoutingRouteEnum.ThemesManagement,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(/* webpackChunkName: "partnerThemeFlow" */ '../domains/themes/views/management/ThemesManagement.vue'),
  },
  {
    path: '/manage-merchant-theme-reviews/:id',
    name: RoutingRouteEnum.ManageMerchantThemeReviews,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(
        /* webpackChunkName: "partnerThemeFlow" */ '../domains/themes/views/management/manage-merchant-theme-reviews/ManageMerchantThemeReviews.vue'
      ),
  },
  {
    path: '/theme-info/:themeId',
    name: RoutingRouteEnum.ThemeInfoContainer,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(
        /* webpackChunkName: "partnerThemeFlow" */ '../domains/themes/views/management/theme-info-container/ThemeInfoContainer.vue'
      ),
  },
  {
    path: '/themes-purchase-list',
    name: RoutingRouteEnum.ThemesPurchaseList,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(/* webpackChunkName: "partnerThemeFlow" */ '../domains/themes/views/purchase-list/ThemesPurchaseList.vue'),
  },
  {
    path: '/subscriptions-list',
    name: RoutingRouteEnum.Subscriptionslist,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(
        /* webpackChunkName: "partnerPageManagement" */ '../domains/subscriptions-list/views/SubscriptionsList.vue'
      ),
  },
  {
    path: '/orders-management',
    name: RoutingRouteEnum.OrdersManagement,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(/* webpackChunkName: "partnerPageManagement" */ '../domains/orders-management/views/OrdersManagement.vue'),
  },
  {
    path: '/activations-management',
    name: RoutingRouteEnum.ActivationsManagement,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(
        /* webpackChunkName: "partnerPageManagement" */ '../domains/activations-management/views/ActivationsManagement.vue'
      ),
  },
  {
    path: '/development-stores',
    name: RoutingRouteEnum.DevelopmentStore,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(
        /* webpackChunkName: "partnerPageManagement" */ '../domains/development-store/views/partner-development-stores/DevelopmentStores.vue'
      ),
  },
  {
    path: '/webhook-logs',
    name: RoutingRouteEnum.WebhookLogs,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(/* webpackChunkName: "partnerPageManagement" */ '../domains/webhooks/views/webhook-logs/WebhookLogs.vue'),
  },
  {
    path: '/help-support',
    name: RoutingRouteEnum.Help,
    meta: { requireAuth: true, requireAccountStepsCompletion: false },
    component: () => import(/* webpackChunkName: "partnerPageManagement" */ '../domains/help/views/Help.vue'),
  },
  {
    path: '/notifications',
    name: RoutingRouteEnum.Notifications,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(/* webpackChunkName: "partnerPageManagement" */ '../domains/notifications/views/Notifications.vue'),
  },
  {
    path: '/bank-account',
    name: RoutingRouteEnum.BankAccount,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(/* webpackChunkName: "partnerPageManagement" */ '../domains/bank-account/views/BankAccount.vue'),
  },
  {
    path: '/coupons',
    name: RoutingRouteEnum.PartnerCoupons,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(/* webpackChunkName: "partnerPageManagement" */ '../domains/coupons/views/ManageCoupons.vue'),
  },
  {
    path: '/profile',
    name: RoutingRouteEnum.Profile,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () => import(/* webpackChunkName: "partnerPageManagement" */ '../domains/profile/views/ProfileInfo.vue'),
  },
  {
    path: '/team-members',
    name: RoutingRouteEnum.TeamMembers,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(
        /* webpackChunkName: "partnerPageManagement" */ '../domains/team-members/views/team-members/TeamMembers.vue'
      ),
  },
  {
    path: '/team-member/:id?',
    name: RoutingRouteEnum.MemberDetails,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(
        /* webpackChunkName: "partnerPageManagement" */ '../domains/team-members/views/member-details/MemberDetails.vue'
      ),
  },
  {
    path: '/partnership-details',
    name: RoutingRouteEnum.partnershipDetails,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () =>
      import(
        /* webpackChunkName: "partnerPageManagement" */ '../domains/partnership-details/views/PartnershipDetails.vue'
      ),
  },
  {
    path: '/payouts',
    name: RoutingRouteEnum.Payouts,
    meta: { requireAuth: true, requireAccountStepsCompletion: true },
    component: () => import(/* webpackChunkName: "partnerPageManagement" */ '../domains/payouts/views/Payouts.vue'),
  },
  {
    path: '/manage-agreements-apps',
    name: RoutingRouteEnum.ManageAgreements_Apps,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isPartnershipAdmin: true },
    component: () =>
      import(/* webpackChunkName: "partnershipsAdmin" */ '../domains/partnerships-admin/views/ManageAgreements.vue'),
  },
  {
    path: '/manage-agreements-themes',
    name: RoutingRouteEnum.ManageAgreements_Themes,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isPartnershipAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "partnershipsAdmin" */ '@/domains/partnerships-admin/views/manage-agreements-themes/ManageAgreementsThemes.vue'
      ),
  },
  {
    path: '/manage-partnerships',
    name: RoutingRouteEnum.ManagePartnerships,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isPartnershipAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "partnershipsAdmin" */ '@/domains/partnerships-admin/views/manage-partnerships/ManagePartnerships.vue'
        ),
  },
  {
    path: '/edit-partnership/:partnershipId',
    name: RoutingRouteEnum.PartnershipEdit,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isPartnershipAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "partnershipsAdmin" */ '@/domains/partnerships-admin/views/edit-partnership/EditPartnership.vue'
        ),
  },
  {
    path: '/manage-partnerships/partner',
    name: RoutingRouteEnum.ManagePartnerships_PartnerContainer,
    component: () =>
      import(
        /* webpackChunkName: "partnershipsAdmin" */ '../domains/partnerships-admin/views/partner-details/PartnerContainer.vue'
      ),
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isPartnershipAdmin: true },
    children: [
      {
        path: 'details',
        name: RoutingRouteEnum.ManagePartnerships_Partner,
        component: () =>
          import(
            /* webpackChunkName: "partnershipsAdmin" */ '../domains/partnerships-admin/views/partner-details/partner/Partner.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: false, isPartnershipAdmin: true },
      },
    ],
  },
  ...PD_ADMIN_ROUTES,
  ...FINANCE_ADMIN_ROUTES,
  {
    path: '/not-authorized/:pageName?',
    name: RoutingRouteEnum.NotAuthorized,
    component: () => import('../domains/NotAuthorized.vue'),
    meta: { requireAuth: true },
  },
  {
    path: '/cli/authorized',
    name: RoutingRouteEnum.CLI_Authorized,
    component: () => import('@/domains/cli/views/AuthorizationSuccessful.vue'),
    meta: { requireAuth: true },
  },
  {
    path: '*',
    name: RoutingRouteEnum.NotFound,
    component: () => import('../domains/NotFound.vue'),
  },
];
