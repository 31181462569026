export const BOOK_MEETING_URL = 'https://developers.zid.sa/meeting';
export const ALL_ARTICLES_URL = 'https://help-partner.zid.sa/';
export const JOIN_SLACK_URL =
  'https://join.slack.com/t/ziddevworkspace/shared_invite/zt-rrhu52k6-XNIWUUTKMZyFNdEtcXvKTA';
export const CHANGELOG_URL = 'https://changelog.partner.zid.sa/';
export const REQUEST_FEATURE_URL = 'https://changelog.partner.zid.sa/feedback';
export const API_DOCUMENTATION = 'https://docs.zid.sa/';
export const EMBEDDED_APP_DOCS =
  'https://docs.zid.sa/embedded-apps';
export const API_DOCUMENTATION_REDIRECT_URL =
  'https://docs.zid.sa';
export const API_DOCUMENTATION_CALLBACK_URL =
  'https://docs.zid.sa';
export const TERMS_OF_USE = 'https://zid.sa/en/terms-conditions/';
// THEMES
export const DEMO_STORE_URL_EXAMPLE = 'https://s3e8s7.zidthemestore.com/';
export const SAMPLE_FEATURE_IMAGE_OF_THEME =
    'https://www.figma.com/design/VJ4Wv7sXVIG1KP8PwNxtDd/Zid-Theme-Store-Image-Guide?t=6iyt2jT0OftZaS2M-0';
export const THEMES_DOCUMENTATION_URL =
    'https://docs.zid.sa/introduction-to-theme-development?nav=01J3VAFCBFWWG3VR6TJX7F6SBX';
// ADMIN LINKS
export const METABASE_PRIVATE_APPS_TABLE_WITH_FILTERS = 'https://metabase.zid.sa/question/2635-apps-query';
export const METABASE_APPS_ANALYTICS = 'http://metabase.zid.sa/public/dashboard/2b956a7b-2510-451b-92cd-5b3c6a78c6c8';
export const METABASE_STORE_LIST =
  'https://metabase.zid.sa/question#eyJkYXRhc2V0X3F1ZXJ5Ijp7ImRhdGFiYXNlIjozLCJ0eXBlIjoicXVlcnkiLCJxdWVyeSI6eyJzb3VyY2UtdGFibGUiOjIxODh9fSwiZGlzcGxheSI6InRhYmxlIiwidmlzdWFsaXphdGlvbl9zZXR0aW5ncyI6e319';
// ADMIN LINKS
// FINANCE ADMIN LINKs
export const METABASE_APPS_ANALYTICS_FINANCE =
  'https://metabase.zid.sa/public/dashboard/2b956a7b-2510-451b-92cd-5b3c6a78c6c8'; //<- May be changed
export const METABASE_SHIPPING_ORDERS_FINANCE =
  'https://metabase.zid.sa/question#eyJkYXRhc2V0X3F1ZXJ5Ijp7ImRhdGFiYXNlIjo0LCJ0eXBlIjoicXVlcnkiLCJxdWVyeSI6eyJzb3VyY2UtdGFibGUiOjcxMzh9fSwiZGlzcGxheSI6InRhYmxlIiwidmlzdWFsaXphdGlvbl9zZXR0aW5ncyI6e319';
export const METABASE_SHIPPING_ACTIVATIONS_FINANCE = '';
// FINANCE ADMIN LINKs
// Development Stores links
export const MERCHANT_DASHBOARD_LOGIN_LINK = 'https://web.zid.sa/login';
// Development Stores links
