import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import { useGlobalGuard } from './guards/useGlobalGuard';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  useGlobalGuard(to, from, next);
});

router.onError((error) => {
  console.error(error);
  if (
    /ChunkLoadError:.*failed./i.test(error.message) ||
    /Loading.*chunk.*failed./i.test(error.message) ||
    /Loading.*CSS.*chunk.*failed/i.test(error.message)
  ) {
    window.location.reload();
  }
});

export default router;
